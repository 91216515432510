/* Overriding default rules for fullscreen in browser. */
:not(:root):-webkit-full-screen::backdrop {
  background-color: rgb(255, 255, 255);
}

.minimal-pannel-size {
  width: 100px !important;

  .ant-time-picker-panel-select {
    width: 50% !important;
  }
}

.changelog {
  p {
    padding-left: 20px;
    padding-bottom: 3px;
    margin-bottom: 0px;
    text-align: justify;

    &::before {
      content: ' ';
      background: #3c8dbc;
      border-radius: 99999px;
      display: inline-block;
      height: 6px;
      margin-bottom: 2px;
      margin-right: 10px;
      width: 6px;
    }
  }

  h3 {
    margin-top: 10px;
  }
}

.userswitch-info {
  background-color: #ff9800;
  color: white;
  text-align: center;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    flex-shrink: 0;
  }

  svg {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    margin-top: 2px;
  }
}

.sticky-student-stages-bar {
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 2;
  padding: 5px 0;
  margin-bottom: 15px;

  .buttons-wrapper {
    display: flex;
    flex-direction: row;

    .desiderata-header-button-upload {
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .sticky-student-stages-bar {
    .buttons-wrapper {
      margin-left: 10px;
      flex-direction: column;

      .desiderata-header-button-upload {
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }
}

.youtube-iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Menu */
.menu {
  background: #222d32;
  background: var(--menu-color);
  color: white;
  transition: width 0.5s;

  .header {
    background: #008fd1;
    background: var(--menu-header-color);
    color: white;
    height: 52px;

    .title {
      font-weight: lighter;
      font-size: 36px;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

  .active {
    border-left: 3px solid white !important;
  }

  .menu-item {
    color: #fff;

    &:hover {
      background-color: white;
      color: black;
      cursor: pointer;
    }

    &:last-child {
      margin-top: auto;
    }
  }
}

@media only screen and (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .hidden-desktop {
    display: none !important;
  }
}

/* Top bar */
.top-bar {
  background-color: #03a9f4;
  background-color: var(--primary-color);
  color: white;

  button {
    box-shadow: 0 0 0 transparent !important;
  }
}

/* Ant overrides */
.ant-form-item-label {
  text-align: left !important;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 35px !important;
  /* Font Awesome icons are larger than basic Ant design. */
}

.ant-btn-primary {
  color: #fff;
  background-color: #03a9f4 !important;
  background-color: var(--primary-color) !important;
  border-color: #03a9f4 !important;
  border-color: var(--primary-color) !important;
}

.ant-btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.ant-select-disabled {
  color: rgba(0, 0, 0, 0.4) !important;
}

.ant-slider {
  margin: 6px 0px 6px !important;
}

.ant-slider-track {
  background-color: #03a9f4 !important;
  background-color: var(--primary-color) !important;
}

.ant-slider-handle {
  border: solid 2px #03a9f4 !important;
  border: solid 2px var(--primary-color) !important;
}

.ant-time-picker-panel {
  width: 150px;

  .ant-time-picker-panel-input-wrap {
    max-width: 150px;
  }

  .ant-time-picker-panel-combobox .ant-time-picker-panel-select {
    width: 75px;
  }
}

.ant-skeleton .ant-skeleton-content ul.ant-skeleton-paragraph {
  padding-inline-start: 0px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3c8dbc !important;
  border-color: #3c8dbc !important;
}

a {
  color: #3c8dbc !important;
}

.ant-tabs {
  overflow: initial !important;
}

.ant-tabs-nav {
  .ant-tabs-tab-active {
    color: #3c8dbc !important;
  }

  .ant-tabs-ink-bar {
    background: #3c8dbc !important;
  }
}

.ant-switch-checked {
  background-color: #7eb73e !important;
}

.internship-schedule .ant-timeline-item {
  padding: 0 0 10px;
}

.internship-schedule {
  .absent-schedule {
    color: red;
  }

  .default-schedule {
    svg {
      color: #03a9f4;
    }
  }
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #3c8dbc !important;
  background-color: transparent !important;
}

button.ant-btn.ant-btn-loading svg:not(.anticon-spin) {
  display: none;
}

input.ant-input {
  line-height: initial;
}

.warning-modal-title {
  color: #ff9800;
}

/* Fonts */
* {
  font-family: 'Roboto', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-weight: 400;
}

/* Layouting elements */
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.v-spacing {
  margin-top: 10px !important;
}

.h-spacing {
  margin-left: 10px !important;
}

.outer-spacing {
  margin: 10px !important;
}

.inner-spacing {
  padding: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-fill {
  flex: 1 0 0;
}

.vertical-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.horizontal-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-center {
  display: flex;
  margin: auto;
}

.select-default {
  color: rgba(150, 150, 155, 1);
}

/* Miscellaneous */
.loading-icon {
  position: relative;
  border: 2px solid transparent;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 5px;
  border-top-color: white;
  border-bottom-color: white;
  animation: loading-rotate 0.8s ease infinite;
}

.loading-icon.black {
  border-top-color: black;
  border-bottom-color: black;
}

@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}

.ant-card.fake-button:hover {
  border: 1px solid #3c8dbc;
  color: #3c8dbc;
  cursor: pointer;
}

.ant-notification {
  &.ant-notification-bottomRight {
    z-index: 9999999999;
  }
}

.zEWidget-launcher {
  z-index: 1 !important;
}

#launcher {
  transition: margin 0.2s ease-out !important;
}

.internships-state-filter {
  ul {
    max-height: 350px;

    .flex-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .state-option-title {
        width: 90%;
        overflow: hidden;
        white-space: pre-wrap;
      }
    }
  }
}

.user-notification-modal {
  .ant-modal-body {
    max-height: 500px;
    overflow: auto;

    img {
      max-width: 472px;
    }

    p {
      padding-left: 20px;
      padding-bottom: 3px;
      margin-bottom: 0px;
      text-align: justify;

      &::before {
        content: ' ';
        background: #3c8dbc;
        border-radius: 99999px;
        display: inline-block;
        height: 6px;
        margin-bottom: 2px;
        margin-right: 10px;
        width: 6px;
      }
    }

    h3 {
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.bold {
  font-weight: bold;
}

.cu-ptr {
  cursor: pointer;
}

.cu-nt-alwd {
  cursor: not-allowed;
}

.student-badge-internship {
  padding: 5px 12px 5px 17px;
  border-bottom: 1px solid #ddd;
}

.student-badge-internship-id {
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.student-badge-note {
  display: flex;
  text-wrap: wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.student-badge-note-text {
  max-width: 200px;
  margin-left: 5px;
}

.student-badge-delete-note {
  margin-top: 5px;
}

.desiderata-modal-presets {
  margin: 0 auto;
  & > div.ant-row {
    border: solid 1px #d3d3d3;
    border-bottom: none;
  }
  & > div.ant-row:last-child {
    border-bottom: solid 1px #d3d3d3;
  }
  & > div.ant-row:hover {
    background-color: #cde9ff;
  }
}

.desiderata-eventcode-select {
  padding: 10px;
  div.ant-select-selection-selected-value {
    div {
      svg {
        display: none
      }
    }
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.states-selector-option {
  display: flex;
  
  label {
    width: 100%;
  }
}

.antd-button-without-padding {
  .ant-btn-sm {
    padding: 0px;
  }
}

.label-input-number {
  .ant-input-number {
    width: 50px;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33%;
}

.w-67 {
  width: 67%;
}

.w-34 {
  width: 34%;
}

.mw-50{
  min-width: 50%;
}

.mw-34{
  min-width: 34%;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mbi-4 {
  margin-bottom: 4px !important;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.pd-4 {
  padding: 4px;
}

.pd-8 {
  padding: 8px;
}

.pd-16 {
  padding: 16px;
}

.pdl-4 {
  padding-left: 4px;
}

.pdl-8 {
  padding-left: 8px;
}

.pdl-12 {
  padding-left: 12px;
}

.pdl-16 {
  padding-left: 16px;
}

.pdr-4 {
  padding-right: 4px;
}

.jtf-ctt-ctr {
  justify-content: center;
}

.jtf-ctt-sbw {
  justify-content: space-between;
}

.aln-itm-ctr {
  align-items: center;
}

.aln-itm-sb {
  align-items: space-between;
}

.bdr-1 {
  border: solid 1px #d9d9d9;
}

.bdr-2 {
  border: solid 2px #d9d9d9;
}

.bdr-rds-4 {
  border-radius: 4px;
}

.bdr-rds-8 {
  border-radius: 8px;
}

.ant-green-button {
  .ant-btn {
    background-color: green !important;
    border-color: green !important;
  }
}

.clickable-icon {
  padding: 2px !important;
  border: 0px !important;
  width: 24px !important;
  height: 24px !important;

  &:hover {
    background-color: #ececef !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }

  &:focus {
    background-color: #ececef !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }
}

.j-ctn-e {
  justify-content: end;
}

.j-ctn-c {
  justify-content: center;
}

.color-secundary {
  color: #3c8dbc;
}


.clr-lgt-grey {
  color: rgba(0, 0, 0, 0.65);
}

.ft-sm {
  font-size: small;
}

.ft-reg {
  font-size: medium;
}

.ft-l {
  font-size: large;
}

.ft-lg {
  font-size: larger;
}

.mh-24p {
  max-height: 24x;
}


.mh-50p {
  max-height: 50px;
}


.mh-100p {
  max-height: 100px;
}


.mh-200p {
  max-height: 200px;
}

.ovy-a {
  overflow-y: auto;
}

.tx-orange {
  color: orange;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.scroll-overflow {
  overflow-y: auto;
}

.h-100 {
  height: 100%;
}

.ft-s-20 {
  font-size: 16px;
}

.fw-b {
  font-weight: bold;
}

.float-btm-ctr {
  position: absolute;
  bottom: 0;
  right: 50%;
}

.actions-row {
  border-radius: 20px;
  padding: 4px 20px 4px 20px;
  background-color: white;
}

.relative {
  position: relative
}

.bdr-rd-4 {
  border-radius: 4px;
}

.bdr-rd-8 {
  border-radius: 8px;
}

.tx-alg-ctr {
  text-align: center;
}

.jst-end {
  justify-content: flex-end;
}

.ant-green-button {
  .ant-btn {
    background-color: green !important;
    color: white !important;
  }
}

.borderless {
  border: 0px !important;
}

.hover-blue {
  &:hover {
    color: #2c98fa;
  }
}

.hover-bg-lightgrey {
  &:hover {
    background-color: #f9f8f8;
  }
}

.form-class {
  .ant-form-item {
    label {
      display: flex;
    }
  }
}

.modal-footer {
  .ant-divider-horizontal {
    margin: 10px 0;
  }

  .ant-btn {
    margin-left: 8px;
  }
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.form-modal {
  .ant-modal-body {
    padding: 0px 0px 10px 0px;
  }

  .form-class {
    padding: 0px 24px;
  }

  .form-footer {
    padding: 0px 24px;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
}