.chat-widget {
  position: absolute;
  bottom: 0px;
  right: 100px;
  width: 350px;
  height: 550px;
  box-shadow: 0px 0px 10px 0px #607d8b;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 9999;

  &.messages-displayed {
    right: 320px;
  }

  .top-bar {
    background-color: #3c8dbc;
    color: white;
    padding: 7px;

    .contact {
      span {
        width: 270px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .subject {
      white-space: wrap;
      overflow: hidden;
    }

    .close-button {
      font-size: 24px;
      margin-right: 7px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .messages-container {
    background: white;
    padding: 10px;
    height: 400px;
    overflow-y: scroll;

    .message {
      .text-bubble {
        padding: 5px 10px;
        border-radius: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &.received {
        .text-bubble {
          background: #efefef;
          color: rgba(0, 0, 0, 0.65);
          margin-right: 50px;
        }
      }

      &.sent {
        .text-bubble {
          background: #3c8dbc;
          color: white;
          margin-left: 50px;
        }
      }

      .timestamp {
        text-align: center;
        color: #3c8dbc;
        font-size: 10px;
      }

      .author-username {
        font-size: 10px;
      }
    }
  }

  .input-container {
    input {
      width: 100%;
      padding: 10px;
      border-top: 1px solid #3c8dbc;
      border-left: none;
      border-right: none;
      border-bottom: none;

      &:focus {
        outline: none;
      }
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    height: 24px;

    button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }

    .ant-btn {
      padding: 0px;
      margin-left: 4px;
      margin-right: 4px;
    }
    
    svg {
      color: white;
    }
  }
}
