$unread-message-font-size: 10px;
$small-text-difference: 2px;

.new-messages-notification-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;

  svg {
    font-size: 24px;
    color: 'whitesmoke';
  }

  .unread-messages-bubble {
    border-radius: 100px;
    background: red;
    font-weight: bold;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $unread-message-font-size;
    transform: translate(1px, -10px)
  }

  &.small-text {
    .unread-messages {
      font-size: $unread-message-font-size - $small-text-difference;
    }
  }
}
