.internships-manager-header {
  .actions-and-filters {
    display: flex;
    flex-direction: row;    
  }

  .table-modifier-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
}

.internship-manager-table {
  width: 100%;
  overflow-x: auto;

  thead {
    th {
      &:first-child {
        position: sticky;
        left: 0px;
        z-index: 2;
      }
    }
  }

  tbody {
    td {
      &:first-child {
        overflow: unset;
        position: sticky;
        left: 0px;
        z-index: 2;
      }
    }
  }

  .internship-card-old {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    max-width: 550px;
    padding-right: 8px;

    .quick-menu-container {
      width: 50px;
    }

    .status-validation-wrapper {
      width: 50px;
    }

    .internship-info-old {
      display: flex;
      flex-direction: column;
      height: 80px;
      width: 445px;
      justify-content: center;
    }
  }
  
  .internship-card-note {
    position: absolute;
    bottom: 60px;
    left: 530px;
  }
}